/* global window, rp$ */
const $onReady = require('public/js/lib/on-ready');
const getCart = () => import(/* webpackChunkName: "get-cart" */ 'public/js/components/cart');
const addToCartSelector = '.add-to-cart.enabled';

const handleCartModule = () => {
	getCart().then((module) => {
		// async cart data load
		module.asyncCartDataLoader();
		
		// set add to cart events
		module.attachAddToCartEvents(addToCartSelector);
	});
};

$onReady({rp$, window,
	label: 'get-cart',
	fn: function () {
		handleCartModule();
	},
});
